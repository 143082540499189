.choosediv {
    padding: 25px;
    background: #fff;
    border-radius: 6px;
    color: #9e9e9e;
    text-align: center;
    transition: 3s;
}

.navBgColor {
    background-color: #00ADF0;
    margin: 2px 0 2px 0;
}


.pageHeading {
    font-weight: bold;
    font-style: italic;
    margin: 20px auto 20px auto;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.productPageHeading {
    font-weight: bold;
    font-style: italic;
    margin: 20px auto 20px auto;
    color: dark;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.footerColorAnchor {
    text-decoration: none;
    color: white
}


.backgroundImg {

    border: 1px solid white;
    border-radius: 35px 35px 10px 10px;

}

.footerCopyright {
    border-top: 1px solid white;
    color: white;
}

.footerAddress {
    color: white;
}

.pageHeadingAbout {
    font-weight: bold;
    font-style: italic;
    margin: 20px auto 20px auto;
    color: dark;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.aboutUsP {
    display: flex;
    text-align: justify;
    color: green;
}

.aboutParagraphP {
    display: flex;
    text-align: justify;
    color: #80081c;
}

.aboutUsContainer {
    border: 2px solid grey;
    border-radius: 10px;
    box-shadow: 10px;
}

.borderClass {

    border: 2px solid #9e9e9e;
    border-radius: 20px;
    padding: 20px 20px;
    /* margin: 20px 20px; */
    box-shadow: 5px 5px #9e9e9e;
    width: fit-content;

}

.headerOfPage {
    color: #80081c;
    margin: 20px;

}




@media screen and (min-width: 480px) {
    .aboutUsPageScreen3 {
        width: 60%;
    }

    .aboutUsPageScreen4 {
        width: 40%;
    }

    .contactUsPageMapInner {
        width: 58%;
        height: 485px;
        margin-top: 6rem;
        margin-bottom: 3rem;


    }

    .contactUsPageMapInnerForm {
        width: 33%;
        margin-top: 3rem;
        margin-bottom: 3rem;

    }

    .contactUsPageMapContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }



}

@media screen and (max-width: 480px) {
    .aboutUsPageScreen {
        display: flex;
        flex-direction: column;
    }

    .aboutUsPageScreen2 {
        width: inherit;
    }

    .footerSmScreen {
        display: flex;
        flex-direction: column;
    }

    .contactUsPageMap {
        flex-direction: column;
    }

    .contactUsPageMapInnerMedia {
        width: inherit;
        height: inherit;
        /* margin-top: 5rem; */
    }

    .contactUsPageMapInnerMost {
        margin-top: 3rem;
    }

}


.DwonloadProductlist {
    display: flex;
    justify-content: space-evenly;
    height: 500px;
    align-items: center;
}

.DownloadGnPdListItem {
    width: 200px;
    height: 200px;
    margin: 20px 20px 20px 20px;
    background-image: url(../Images/Banner/inflamation.jpg);
}

.DownloadBtPdListItem {
    width: 200px;
    height: 200px;
    margin: 20px 20px 20px 20px;
    background-image: url(../Images/Banner/antibiotic.jpg);
}

.TextInCenter {
    display: flex;
    text-align: center;
}